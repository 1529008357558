import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { navigate } from "@reach/router";
import ReactAudioPlayer from "react-audio-player";
const MicRecorder = require("mic-recorder-to-mp3");
const recorder = new MicRecorder({
  bitRate: 256,
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
  },
  btn: {
    height: "15vh",
    fontSize: 50,
  },
  containedPurple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: purple[500],
      },
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    color1: {
      color: purple,
    },
  },
});
export default function Record() {
  const classes = useStyles();
  const MAX = 10; //seconds
  const [open, setOpen] = React.useState(false);
  const [btnText, setBtnText] = useState("Record");
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [ready, setReady] = useState(false);
  const [file, setFile] = useState();
  const [audioUrl, setAudioUrl] = useState();
  let player = null;

  useEffect(() => {
    // handleClickOpen();
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    if (seconds == MAX + 1) {
      stop();
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
      reset();
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const cancel = () => {
    navigate("/");
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function start() {
    setSeconds(0);
    setReady(false);

    recorder
      .start()
      .then(() => {
        // something else
        console.log("started");
        setIsActive(true);
      })
      .catch((e) => {
        console.error(e);
      });
  }
  function toggle() {
    setIsActive(!isActive);
  }

  function stop() {
    setIsActive(false);
    setReady(true);
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "voice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        setFile(file);
        const __audioUrl = URL.createObjectURL(file);
        setAudioUrl(__audioUrl);
        player = new Audio(URL.createObjectURL(file));
        player.play();
      })
      .catch((e) => {
        alert("We could not retrieve your message");
        console.log(e);
      });
  }
  function reset() {
    setSeconds(0);
    setIsActive(false);
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Our terms of Service"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod
              maxime placeat facere possimus, omnis voluptas assumenda est,
              omnis dolor repellendus. Temporibus autem quibusdam et aut
              officiis debitis aut rerum necessitatibus saepe eveniet ut et
              voluptates repudiandae sint et molestiae non recusandae. Itaque
              earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
              voluptatibus maiores alias consequatur aut perferendis doloribus
              asperiores repellat.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} color="primary">
              Disagree
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Container maxWidth="sm">
          <div className={classes.root}>
            <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              size="large"
              onClick={start}
            >
              {isActive ? "Recording" : "Start"}{" "}
              {seconds != 0 && !ready && seconds}
              {/* {btnText} */}
            </Button>
            <Button
              disabled={!ready}
              className={classes.btn}
              variant="contained"
              color="primary"
              size="large"
              onClick={reset}
            >
              Submit
              {/* {btnText} */}
            </Button>
            {player}
            <ReactAudioPlayer src={audioUrl} autoPlay controls />
          </div>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}
