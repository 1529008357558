import React from "react";
import { useNavigate } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
  },
  btn: {
    height: "15vh",
    fontSize: 50,
  },
  containedPurple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: purple[500],
      },
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    color1: {
      color: purple,
    },
  },
});
export default function Home() {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <div className={classes.root}>
            <Button
              className={[classes.btn]}
              variant="contained"
              color="primary"
              size="large"
            >
              Play
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => navigate("/record")}
            >
              Record
            </Button>
            <Button
              className={[classes.btn, classes.containedPurple]}
              variant="contained"
              color="color1"
              size="large"
            >
              Buy Ticket
            </Button>
          </div>
          {/* <Typography component="div" style={{ backgroundColor: '#cfe8fc', height: '100vh' }} /> */}
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}
