import React from "react";
import { Switch, Route } from "react-router-dom";
import { Router, Link } from "@reach/router";
import Home from "./views/Home";
import Record from "./views/Record";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <Router>
      <Home path="/" />
      <Record path="/record" />
    </Router>
  );
}

export default App;
